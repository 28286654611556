.dateContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 8px 16px;
    padding: 10px;

}
.fullWidthColumn {
    grid-column: 1 / span 2;
}
